@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts  */
/* fonts  */

@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@500&display=swap');

body{
    font-family: 'Assistant', sans-serif;
  }



/* @font-face {
    font-family: gilroyLight;
    src: url(../assets/fonts/gilroy/Gilroy-Light.ttf);
}
@font-face {
    font-family: gilroyBold;
    src: url(../assets/fonts/gilroy/Gilroy-Bold.ttf);
}
@font-face {
    font-family: gilroyMedium;
    src: url(../assets/fonts/gilroy/Gilroy-Medium.ttf);
}
@font-face {
    font-family: gilroyEBold;
    src: url(../assets/fonts/gilroy/Gilroy-ExtraBold.ttf);
} */
@font-face {
    font-family: montserratEBold;
    src: url(../assets/fonts/Montserrat/static/Montserrat-ExtraBold.ttf);
}

@font-face {
    font-family: montserratBold;
    src: url(../assets/fonts/Montserrat/static/Montserrat-Bold.ttf);
}

.montserratEBold{
    font-family: "montserratEBold";
    font-weight: 900; /* Extra bold font weight */
    text-shadow: 4px 4px 4px #7F5277;
}

@font-face {
    font-family: montserratLight;
    src: url(../assets/fonts/Montserrat/static/Montserrat-Light.ttf);
}

.montserratLight{
    font-family: 'montserratLight';
    font-weight: 300; /* Extra bold font weight */
    text-shadow: 4px 4px 4px #7F5277;
}

.montserratBold{
    font-family: "montserratBold";
    font-weight: 900; 
    text-shadow: 4px 4px 4px #7F5277;
}

.gilroyLight {
    font-family: 'Assistant', sans-serif;
    font-weight: 300; /* Light font weight */
}

.gilroyBold {
    font-family: 'Assistant', sans-serif;
    font-weight: 700; /* Bold font weight */
}

.gilroyEBold {
    font-family: 'Assistant', sans-serif;
    font-weight: 800; /* Extra bold font weight */
}

.gilroyMedium {
    font-family: 'Assistant', sans-serif;
    font-weight: 500; /* Medium font weight */
}


/* model  */
/* model  */


.quote-bg{
    background-image: url(../assets/image/bg/signbg.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* nav  */
/* nav  */



/* homepage */
/* homepage */

.video-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    padding: 150px 0px 150px 0px;
}

.hsec-three-title{
    font-size: 2.5rem;
    line-height: 50px;
}
.hsec-three-sub{
    margin-top: 25px;
    font-size: 1.5rem;
}
.text-high{
    background-color: #6C3E2E;
}
.leaf {
    width: 200px; /* Adjust width as needed */
    height: 300px; /* Adjust height as needed */
    position: relative;
    background-color: #F7AE45;
    transform: skew(20deg); /* Adjust skew angle as needed */
    overflow: hidden;
    border-radius: 35px 0 35px 0; /* Adjust border radius as needed */
}
.line-large{
    width: 200px;
    height: 4px;
    background-color: #F7AE45;
    margin-top: 15px;
    border-radius: 0 10px 10px 0;
}
.linec-large{
    width: 100px;
    justify-self: center;
    align-self: center;
    height: 3px;
    background-color: #F7AE45;
    border-radius: 10px;
}
.hsec-two-title{
    font-size: 2.5rem;
}
.cor-btn{
    box-shadow: none;
    color: #ffffff;
}

.interwood {
    position: relative;
    display: inline-block;
    animation: slideInOut 2s infinite;
    animation-timing-function: linear;
}

@keyframes slideInOut {
0%, 100% { opacity: 0; transform: translateY(-20px); }
10%, 90% { opacity: 1; transform: translateY(0); }
}

.prod-title{
    margin-top: 40px;
    font-size: 2.2rem;
    margin-bottom: 10px;
}

.prod-gridone{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
    gap: 10px;
    margin-top: 30px;
}

.prod-gridtwo{
    display: grid;
    grid-template-columns: 1.2fr 1.6fr 1.2fr;
    padding: 10px;
    gap: 10px;
}

.prod-griditem{
    border-radius: 22px;
    max-height: 300px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.prod-griditem:hover{
    transform: translateY(-10px);
}

.why-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.why-griditem{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 100px;
}

.why-img{
    width: 80%;
}

.why-title{
    text-align: center;
    margin-top: 100px;
}



/* client prev */
/* client prev */
/* client prev */

.carousel-container {
    position: relative;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .carousel-navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-200%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0px;
  }
  
  .carousel-nav-button {
    border: none;
    background: transparent;
    font-size: 24px;
    color: #6C3E2E;
    cursor: pointer;
  }
 

  .cp-title{
    text-align: center;
}

.client-prev-cont{
    margin: 50px 0;
}


/* prev */
/* prev */
/* prev */


.p-title{
    display: flex;
    justify-content: space-between;
    align-items: center;    
}

.p-text{
    display: flex;
    justify-self: center;
    align-self: center;
}
.process-title{
    text-align: center;
    margin-top: 60px;
    font-size: 2.2rem;
}

/* bottom */
/* bottom */
/* bottom */

.bot-main{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}
.bot-title{
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 0 auto;
}

/* footer */
/* footer */
/* footer */

.footer{
    background-image: linear-gradient(to left, #7F5277, #41303E);
}


.slogo-cont{
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: auto;
    gap: 30px;
}
.slogo{
    height: 30px;
    margin: 10px;
}

.lsocial-cont{
    display: flex;
    justify-content: space-between;
}
.flogo{
    width: 250px;
}
.navo-cont{
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    margin-top: 150px;
    padding-left: 80px;
}
.fnav{
    font-size: 1.1rem;
}

.style14 {
    border: 0;
    height: 2px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #7F5277, rgba(0, 0, 0, 0));
}

.branch-cont{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.branch-card{
    border: 2px solid #ccc;
    padding: 10px;

}

.career-card{
    display: flex;
    justify-content: center;
    align-items: center;
}
  
/* HW landing */
/* HW landing */
/* HW landing */

.hland-img{
    max-height: 100vh;
}

.hlandtwo{
    background-image: url(../assets/image/bg/how.webp);
    min-height: 80vh;
    max-height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.steps-title{
    /* font-size: 2.2rem; */
}
.steps-sub{
    /* font-size: 1.4rem; */
    margin-top: 10px;
}
.step-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.step-griditem{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.step-img{
    width: 80%;
}

.steps-sec{
   
    margin-top: 90px;
    margin-bottom: 90px;
}

.step-cardt{
    font-size: 1.7rem;
}

.step-cardsub{
    margin-top: 20px;
}

.quote{
    width: 50%; 
    margin-left: 12px;
}
.tick{
    width: 50%; 
    margin-right: -20px;
}

.team-grid{
    gap: 40px;
}

.team-griditem{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 100px;
}

.team-img{
    width: 80%;
}

.team-title{
    font-size: 1.4rem;
}
.team-des{
    font-size: 1rem;
    text-align: center;
}
.subs-cont{
    width: 52%;
}
.subs-main{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.subs-cardcont{
    /* background-image: linear-gradient(to left, #7F5277, #41303E); */
   width: 100%;
   padding: 30px;
   display: flex;
   justify-content: space-between;
   flex-direction: column;
   gap: 10px;

}

/* design ideas */
/* design ideas */
/* design ideas */

.card-grid{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}
.title-container {
    display: flex;
    align-items: center;
    padding: 30px 0;
  }
  
  .gold-line {
    width: 4px; /* Adjust the width as needed */
    height: 60px; /* Adjust the height as needed */
    background-color: #F7AE45;
    margin-right: 10px; /* Adjust the margin as needed */
    border-radius: 20px;
  }
  
  .title {
    margin: 0;
  }
  
/* interio */
/* interio */
/* interio */

.in-detgrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    padding: 30px 0;
}

.in-detcards{
    transition: transform 0.2s ease;
}

.in-detcards:hover{
    transform: translateY(-10px);
}

.detimage-gal-one{
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 20px;
}
.detimage-gal-two{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 100px;
}

.gal-card{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    justify-content: start;
    align-items: end;
    padding: 10px;
}

/* magazine */
/* magazine */
/* magazine0 */

.mag-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 30px;
}
.carousel-container {
    width: 100%;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-card {
    flex: 0 0 calc(100% / 5);
    padding: 20px;
    border: 1px solid #ccc;
    text-align: center;
    box-sizing: border-box;
    background-color: #f7f7f7;
    margin: 0 10px;
  }
  
  .active {
    transform: translateX(-20%);
  }

  /* commercial */
  /* commercial */
  /* commercial */

  .com-detgrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 20px 0;
  }

  .com-griditem{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 280px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    justify-content: start;
    align-items: end;
    padding: 10px;
    transition: transform 0.2s ease;
  }
  .com-griditem:hover{
    transform: translateY(-10px);
  }

  /* cstory */
  /* cstory */
  /* cstory */

  .cs-topcont{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cs-cardgrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px 0;
    gap: 25px;
  }
  .csloc{
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .cl-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 80px 20px;
    padding: 20px 0;
    margin-top: 60px;
    margin-bottom: 60px;
  }



  /* interio  */

  .slider {
    position: relative;
    width: 80vw;
    overflow: hidden;
    margin: 0 auto;
  }
  
  .slider-inner {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  
  .slide {
    flex: 0 0 100%;
  }
  
 
  
  .prev-button,
  .next-button {
    position: absolute;
    top: 57%;
    transform: translateY(-50%);
    background-color: #ffffff;
    color: white;
    border-radius: 200px;
    border: none;
    height: 50px;
    width: 50px;
    opacity: 0.7;
    font-size: 24px;
    padding: 8px 16px;
    cursor: pointer;
  }

  .vert{
    top: 43%;
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }

  /* showroom */
  /* showroom */

  .sr-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }

  .sr-card{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 16px;
    margin-top: 80px;
  }

  .sr-ytcont{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sr-picslide{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .con-main{
        background: linear-gradient(-45deg, #7F5277, #F7AE45, #553B51, #41303E);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        min-height: 85vh;
        
  }
    
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
     
        50% {
            background-position: 100% 50%;
        }
     
        100% {
            background-position: 0% 50%;
        }
    }
  

    .con-card{
        width: 50%;
        background-color: #ffffff;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px 0;
        border-radius: 15px;
    }

    .con-cont{
        padding: 20px;
    }

    .con-element{
        display: flex;
        justify-content: space-between;
        gap: 40px;
        align-items: center;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 20px 80px;
        margin: 30px 5px; 
        width: 100%;
        border-radius: 30px;
    }


    .glass-container {
        position: relative;
        width: 100%; /* Adjust the width as needed */ /* Adjust the height as needed */
        background: url(../assets/image/bg/shopfit.webp) center/cover; /* Add your background image */
        border-radius: 10px; /* Adjust the border radius as needed */
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 85vh;
      }
      
      .glass-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.2); /* Adjust the background color and opacity */
        border-radius: 10px; /* Adjust the border radius to match the container */
        backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      /* Additional styles for the text */
      .glass-content p {
        color: #fff; /* Text color */
      }


      .homepage-card {
       
        transition: width 0.3s ease-in-out; 
      }


      

.partner-slider{
     margin:auto;
     overflow: hidden;
     position: relative;
     width: auto;
}
.partner-slider .partner-slide-track{
    animation:  scroll 15s linear infinite;
     display:flex;
     width:calc(200px * 36);
}

.partner-slider .partner-slide{
     width:200px

}
@keyframes scroll {
     0%{
        transform:translate translateX(0);   
      }
      100%{
        transform: translateX(calc(-200px * 18));
      }
}

/* Laptop styles */
@media (min-width: 1024px) {
    .titlefontsize {
        font-size: 24px; /* Laptop title font size */
    }
    .paragraphfontsize {
        font-size: 16px; /* Laptop paragraph font size */
    }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
    .titlefontsize {
        font-size: 20px; /* Tablet title font size */
    }
    .paragraphfontsize {
        font-size: 14px; /* Tablet paragraph font size */
    }
}

/* Mobile styles */
@media (max-width: 767px) {
    .titlefontsize {
        font-size: 18px; /* Mobile title font size */
    }
    .paragraphfontsize {
        font-size: 14px; /* Mobile paragraph font size */
    }
}

::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  
      

